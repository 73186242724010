<template>
  <form autocomplete="off" class="">
    <div class="row g-3 g-lg-4">
      <div class="col-12">
        <label class="form-label" for="input_page_title">Page title</label>
        <textarea id="input_page_title" v-model="data.page_title" class="form-control" rows="2" placeholder="Page title" name="page_title"></textarea>
      </div>
      <div class="col-12">
        <label class="form-label" for="short_desc">Short description</label>
        <textarea id="short_desc" v-model="data.short_description" class="form-control" rows="3" placeholder="Write short description" name="short_desc"></textarea>
      </div>
      <div class="col-12">
        <label class="form-label" for="main_description">Main description</label>
        <div class="ckeditor-classic">
          <ckeditor v-model="data.description" :editor="editor" :config="config"></ckeditor>
        </div>
      </div>
      <div class="col-12">
        <label class="form-label" for="input_meta_title">Meta title</label>
        <input type="text" v-model="data.meta_title" id="input_meta_title" name="meta_title" class="form-control" placeholder="Meta title" />
      </div>
      <div class="col-12">
        <label class="form-label" for="input_meta_keys">Meta keys</label>
        <input type="text" v-model="data.meta_keys" id="input_meta_keys" name="meta-keys" class="form-control" placeholder="e.g: Logo Design, Letterhead, Poster Design" />
      </div>
      <div class="col-12">
        <label class="form-label" for="meta_desc">Meta description</label>
        <textarea id="meta_desc" v-model="data.meta_desc" class="form-control" rows="3" placeholder="Meta description" name="meta-desc"></textarea>
      </div>
      <div class="col-12">
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="dark" 
          @click.prevent="update()"
            type="button">Save Changes</b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    setup() {
      return { v$: useVuelidate() };
    },
    components:{
      ckeditor: CKEditor.component
    },
    props:{
      product:{
        type: Object,
        default: null
      },
    },
    data(){
      return{
        data: {},
        editor: ClassicEditor,
        config:{
          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
            ]
          }
        }
      }
    },
    methods:{
        update(){
          this.v$.$validate().then(result =>{
            if (!result) return;
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/products/${this.product.id}/update-metas`,formData)
              .then((response) => {
                  this.$store.dispatch("changeLoaderValue", false)
                  if(response.data.success){
                      this.$store.commit("productList/UPDATE_PRODUCT", response.data.data)
                      this.resetForm()
                  }
              })
          })
        },
        patchUpdatables() {
          if(this.product.description){
            this.data = {
              meta_title: this.product.description.meta_title,
              meta_keys: this.product.description.meta_keys,
              meta_desc: this.product.description.meta_desc,
              page_title: this.product.description.page_title,
              short_description: this.product.description.short_description,
              description: this.product.description.description || ""
            }
          }
        },
    },
    mounted(){
      this.patchUpdatables()
    }
}
</script>