
<template>
  <div class="product-questions">
    <div class="row">
      <div class="col-12">
        <div class="mb-3 d-flex align-items-center justify-content-between">
            <h5 class="my-0">  Product Questions </h5>
        </div>
        <b-accordion class="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary"
           id="questionAccordionBordered">
          <b-accordion-item :title="question.question"
              v-for="(question, index) in questions" :key="index"
              :visible="index == 0">
              <div>
                <div class="mb-1" v-html="preText(question.answer)"></div>
                <div class="mt-3">
                  <button @click.prevent="initEdit(question)" class="btn me-2 btn-sm btn-primary">
                  <i class="ri ri-edit-2-line align-bottom me-1"></i> Edit </button>
                  <button  @click.prevent="deleteItem(question.id)" class="btn btn-sm btn-danger">
                  <i class="ri ri-delete-bin-line align-bottom me-1"></i> Delete </button>
                </div>
              </div>
          </b-accordion-item>
        </b-accordion>
      </div>
      <div class="col-12 mt-4 text-end">
        <b-button variant="primary" size="sm" @click.prevent="popupModalShow = true" type="button">
            Add Question</b-button>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update question':'Add question'"
      title-class="fs-6" @hide="initializeAll()">
      <product-question-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editMode="editMode" :productId="product.id" :editItem="editItem" />
    </b-modal>
  </div>
</template>

<script>
import ProductQuestionForm from '@/components/forms/ProductQuestionForm.vue'

export default {
  components: { ProductQuestionForm },
  data() {
    return {
      popupModalShow: false,
      editMode: false,
      editItem: null,
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    },
    questions(){
      return this.product.questions
    }
  },
  methods: {
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this question!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/product-questions/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("productList/DELETE_QUESTION",itemId)
            }
          })
        }
      });
    },
    initEdit(items){
      this.editMode = true;
      this.editItem = {...items}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
}
</script>
