
<template>
  <div class="product-requirements">
    <div class="row">
      <div class="col-12">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <h5 class="my-0">  Product Requirements </h5>
        </div>

        <b-accordion class="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary"
           id="requirementAccordionBordered">
          <b-accordion-item :title="requirement.name"
            v-for="(requirement, index) in requirements" :key="index"
            :visible="index == 0">
              <requirement-card :item="requirement" >
                <template v-slot:actions>
                  <button @click.prevent="initEdit(requirement)" class="btn me-2 btn-sm btn-primary">
                  <i class="ri ri-edit-2-line align-bottom me-1"></i> Edit </button>
                  <button @click.prevent="showValues(requirement)" class="btn me-2 btn-sm btn-success">
                  <i class="ri ri-edit-2-line align-bottom me-1"></i> View values </button>
                  <button  @click.prevent="deleteItem(requirement.id)" class="btn btn-sm btn-danger">
                  <i class="ri ri-delete-bin-line align-bottom me-1"></i> Delete </button>
                </template>
              </requirement-card>
          </b-accordion-item>
        </b-accordion>
      </div>
      <div class="col-12 mt-4 text-end">
        <b-button variant="warning" size="sm" class="me-3" @click.prevent="popupClone = true" type="button">
          Copy from </b-button>
        <b-button variant="primary" size="sm" @click.prevent="popupModalShow = true" type="button">
          Add Requirement</b-button>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode ? 'Update requirement':'Add requirement'"
      title-class="" @hide="initializeAll()">
      <requirement-form v-if="popupModalShow" @closeMe="initializeAll()"
       :editMode="editMode" :productId="product.id" :editItem="showItem" />
    </b-modal>

    <b-modal v-model="popupModalValues" size="lg" hide-footer centered
      title="Requirement Values"
      title-class="" @hide="initializeAll()">
      <product-requirement-values v-if="popupModalValues"
       :showItem="showItem" />
    </b-modal>

    <b-modal v-model="popupClone" hide-footer centered
      title="Select product to copy from"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <select v-model="cloneFrom"
          class="form-control mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, iIndex) in products" :key="iIndex"
          :value="dPro.id" >{{dPro.name}}</option>
        </select>
        <b-button variant="primary" @click.prevent="cloneNow()" type="button">Clone</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

import RequirementForm from '@/components/forms/RequirementForm.vue';
import RequirementCard from '@/components/cards/RequirementCard.vue';
import ProductRequirementValues from './ProductRequirementValues.vue';

export default {
  components: { 
    RequirementForm,RequirementCard,
    ProductRequirementValues
  },
  data() {
    return {
      popupModalShow: false,
      popupModalValues: false,
      editMode: false,
      showItem: null,
      popupClone: false,
      cloneFrom: null
    }
  },
  computed:{
    product(){
      return this.$store.state.productList.product
    },
    requirements(){
      return this.product.requirements
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
  },
  methods: {
    cloneNow(){
      if(!this.cloneFrom){ this.alertError("Select product"); return;}
      let data = {from: this.cloneFrom, to: this.product.id}
      this.$store.dispatch("changeLoaderValue",true)
      this.$http.post('/product-requirements/clone', data)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct", this.product.id)
          this.initializeAll()
        }
      })
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this requirement!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/product-requirements/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
               this.$store.commit("productList/DELETE_REQUIREMENT", itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.showItem = {...item}
      this.popupModalShow = true  
    },
    showValues(item){
      this.showItem = {...item}
      this.popupModalValues = true  
    },
    initializeAll(){
      this.editMode = false;
      this.showItem = null;
      this.popupModalShow = false;
      this.popupModalValues = false;
      this.popupClone = false;
      this.cloneFrom = null
    },
  },
}
</script>
