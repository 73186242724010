
<template>
  <div class="">
    <div v-if="!isLoading" class="row">
      <div class="col-12">
        <h4 class="mb-3"> {{product.name}}</h4>
        <b-tabs pills card nav-class="nav nav-pills arrow-navtabs nav-success bg-light mb-3">
          <b-tab title="Overview" active>
            <div class="card">
              <div class="card-body">
                <product-form :editItem="product" :editMode="true" />
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                  <product-banner />
              </div>
            </div>
          </b-tab>
          <b-tab title="Description">
            <div class="card">
              <div class="card-body">
                <product-description-form :product="product"/>
              </div>
            </div>
          </b-tab>
          <b-tab title="Attributes">
            <div class="card">
              <div class="card-body">
                <product-attributes />
              </div>
            </div>
          </b-tab>
          <b-tab title="Packages">
            <div class="card">
              <div class="card-body">
                <product-package-form :product="product" />
              </div>
            </div>
          </b-tab>
          <b-tab title="Files">
            <div class="card">
              <div class="card-body">
                <product-files />
              </div>
            </div>
          </b-tab>
          <b-tab title="Settings">
            <div class="card">
              <div class="card-body">
                <product-addons />
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <product-questions />
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <product-requirements />
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div v-if="isLoading" class="row">
      <div class="col-12">
        <is-loading />
      </div>
    </div>
  </div>
</template>

<script>
import ProductForm from "@/components/forms/ProductForm.vue"
import IsLoading from "@/components/IsLoading.vue"
import ProductDescriptionForm from '@/components/forms/ProductDescriptionForm.vue'
import ProductPackageForm from '@/components/forms/ProductPackageForm.vue'
import ProductAttributes from '@/components/product/ProductAttributes.vue'
import ProductFiles from '@/components/product/ProductFiles.vue'
import ProductAddons from '@/components/product/ProductAddons.vue'
import ProductQuestions from '@/components/product/ProductQuestions.vue'
import ProductRequirements from '@/components/product/ProductRequirements.vue'
import ProductBanner from '@/components/product/ProductBanner.vue'

export default {
  name: "product-list",
  components:{
    IsLoading,
    ProductForm,
    ProductDescriptionForm,
    ProductPackageForm,
    ProductAttributes,
    ProductFiles,
    ProductAddons,
    ProductQuestions,
    ProductRequirements,
    ProductBanner,
  },
  data() {
    return {
      isLoading: true,      
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    },
  },
  methods: {
    fetchProduct() {
      this.isLoading = true
      this.$store.dispatch("productList/fetchProduct", this.$route.params.productId)
      .then((response) => {
        if(response.data.success){
          this.isLoading = false
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
  },
  created(){
    this.fetchProduct()
     this.$store.dispatch("productList/fetchProducts")
    this.$store.dispatch("categoryList/fetchCategories")
  },
}

</script>

