
<template>
  <div class="product-package-form">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-bordered table-striped mt-3">
            <thead>
              <tr>
                <th></th>
                <th class="text-center"  style="min-width: 100px">
                  Basic
                </th>
                <th class="text-center" style="min-width: 100px">
                  Standard
                </th>
                <th class="text-center" style="min-width: 100px">
                  Premium
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-nowrap" scope="row">Package Name <span class="text-danger">*</span></th>
                <td>
                  <div class="mb-0">
                    <input type="text" v-model="data.basic.name" name="basic-name" 
                    class="form-control" placeholder="Enter name"
                    :class="{ 'is-invalid': v$.data.basic.name.$error }" />
                    <div v-for="(item, index) in v$.data.basic.name.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-0" >
                    <input type="text" v-model="data.standard.name" name="standard-name"
                     class="form-control" placeholder="Enter name" 
                     :class="{ 'is-invalid': v$.data.standard.name.$error }"/>
                    <div v-for="(item, index) in v$.data.standard.name.$errors" :key="index"
                        class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-0">
                    <input type="text" v-model="data.premium.name" name="premium-name" 
                    class="form-control" placeholder="Enter name" 
                    :class="{ 'is-invalid': v$.data.premium.name.$error }"/>
                    <div v-for="(item, index) in v$.data.premium.name.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap" scope="row">Price (dollar) <span class="text-danger">*</span></th>
                <td>
                  <div class="mb-0">
                    <input type="number" v-model="data.basic.price" class="form-control"
                       name="basic-price" placeholder="Enter price"
                       :class="{ 'is-invalid': v$.data.basic.price.$error }" />
                      <div v-for="(item, index) in v$.data.basic.price.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-0">
                    <input type="number" v-model="data.standard.price" class="form-control"
                     name="standard-price" placeholder="Enter price" 
                     :class="{ 'is-invalid': v$.data.standard.price.$error }"/>
                      <div v-for="(item, index) in v$.data.standard.price.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-0">
                    <input type="number" v-model="data.premium.price" class="form-control"
                      name="premium-price" placeholder="Enter price"
                      :class="{ 'is-invalid': v$.data.premium.price.$error }"/>
                      <div v-for="(item, index) in v$.data.premium.price.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap" scope="row">Delivery Time (hrs) <span class="text-danger">*</span></th>
                <td>
                 <div class="mb-0">
                    <select v-model="data.basic.delivery_time"
                      class="form-control" placeholder="--select day--"
                      :class="{ 'is-invalid': v$.data.basic.delivery_time.$error }">
                      <option selected value="" >--select day--</option>
                      <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                      :value="dTime.value" >{{dTime.text}}</option>
                    </select>
                      <div v-for="(item, index) in v$.data.basic.delivery_time.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                 <div class="mb-0">
                    <select v-model="data.standard.delivery_time"
                      class="form-control" placeholder="--select day--"
                      :class="{ 'is-invalid': v$.data.standard.delivery_time.$error }">
                      <option selected value="" >--select day--</option>
                      <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                      :value="dTime.value" >{{dTime.text}}</option>
                    </select>
                      <div v-for="(item, index) in v$.data.standard.delivery_time.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                 <div class="mb-0">
                    <select v-model="data.premium.delivery_time"
                      class="form-control" placeholder="--select day--"
                      :class="{ 'is-invalid': v$.data.premium.delivery_time.$error }">
                      <option selected value="" >--select day--</option>
                      <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                      :value="dTime.value" >{{dTime.text}}</option>
                    </select>
                      <div v-for="(item, index) in v$.data.premium.delivery_time.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap" scope="row">Revision <span class="text-danger">*</span></th>
                <td>
                  <div class="mb-0">
                    <input type="text" v-model="data.basic.revisions" name="basic-revisions"
                     class="form-control" placeholder="e.g: 5 Days, Unlimited" 
                     :class="{ 'is-invalid': v$.data.basic.revisions.$error }"/>
                      <div v-for="(item, index) in v$.data.basic.revisions.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-0">
                    <input type="text" v-model="data.standard.revisions" name="standard-revisions"
                      class="form-control" placeholder="e.g: 5 Days, Unlimited"
                      :class="{ 'is-invalid': v$.data.standard.revisions.$error }" />
                      <div v-for="(item, index) in v$.data.standard.revisions.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-0">
                    <input type="text" v-model="data.premium.revisions" name="premium-revisions" 
                      class="form-control" placeholder="e.g: 5 Days, Unlimited" 
                      :class="{ 'is-invalid': v$.data.premium.revisions.$error }"/>
                      <div v-for="(item, index) in v$.data.premium.revisions.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="text-nowrap" scope="row">Description <span class="text-danger">*</span></th>
                <td>
                  <div class="mb-0">
                    <textarea v-model="data.basic.description" class="form-control" rows="2" 
                    :class="{ 'is-invalid': v$.data.basic.description.$error }" name="basic-desc"></textarea>
                    <div v-for="(item, index) in v$.data.basic.description.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-0">
                    <textarea v-model="data.standard.description" class="form-control" rows="2"
                    :class="{ 'is-invalid': v$.data.standard.description.$error }" name="standard-desc"></textarea>
                    <div v-for="(item, index) in v$.data.standard.description.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-0">
                    <textarea v-model="data.premium.description" class="form-control" rows="2"
                    :class="{ 'is-invalid': v$.data.premium.description.$error }" name="premium-desc"></textarea>
                    <div v-for="(item, index) in v$.data.premium.description.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </td>
              </tr>

              <template v-for="(attribute, index) in attributes">
                <tr v-if="attribute.type == 'check'" :key="index">
                  <th class="text-nowrap" scope="row">{{attribute.name}} </th>
                  <td class="text-center">
                    <b-form-checkbox v-model="data.basic.attributes[attribute.id]" :name="'checkbox-'+attribute.id" value="supported" unchecked-value="not_supported">
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox v-model="data.standard.attributes[attribute.id]" :name="'checkbox-'+attribute.id" value="supported" unchecked-value="not_supported">
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox v-model="data.premium.attributes[attribute.id]" :name="'checkbox-'+attribute.id" value="supported" unchecked-value="not_supported">
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr v-if="attribute.type == 'input'" :key="index">
                  <th class="text-nowrap" scope="row">{{attribute.name}} </th>
                  <td>
                    <div class="mb-0">
                      <input type="text" v-model="data.basic.attributes[attribute.id]" class="form-control" placeholder="Enter value" />
                    </div>
                  </td>
                  <td>
                    <div class="mb-0">
                      <input type="text" v-model="data.standard.attributes[attribute.id]" class="form-control" placeholder="Enter value" />
                    </div>
                  </td>
                  <td>
                    <div class="mb-0">
                      <input type="text" v-model="data.premium.attributes[attribute.id]" class="form-control" placeholder="Enter value" />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
        </table>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="light" @click.prevent="resetData()" type="button" class="me-2">Reset All</b-button>
          <b-button variant="dark" @click.prevent="submitPackage()" type="button">Save Changes</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      data: {
        basic:{
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description:"",
          attributes:{}
        },
        standard: {
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description: "",
          attributes:{}
        },
        premium:{
          name: "",
          price: "",
          delivery_time: "",
          revisions: "",
          description: "",
          attributes:{}
        },
      },
      defaultData: null
    }
  },
  validations() {
    return{
      data: {
        basic:{
          name:{required: required},
          price:{required: required, numeric: numeric},
          delivery_time:{required: required},
          revisions:{required: required},
          description:{maxLength: maxLength(100)},
        },
        standard: {
          name:{required: required},
          price:{required: required, numeric: numeric},
          delivery_time:{required: required},
          revisions:{required: required},
          description:{maxLength: maxLength(100)},
        },
        premium:{
          name:{required: required},
          price:{required: required, numeric: numeric},
          delivery_time:{required: required},
          revisions:{required: required},
          description:{maxLength: maxLength(100)},
        },
      },
    }
  },
  props:{
    product:{
      type: Object,
      default: null
    },
  },
  computed: {
    packages(){
      return this.product.product_packages
    },
    attributes(){
      return this.product.attributes
    },
    deliveryDays(){
      let days = [{value: 24, text: "1 day"}];
      for (let step = 2; step < 101; step++) {
        days.push({value: step*24, text: `${step} days`});
      }
      return days;
    }
  },
  methods: {
    submitPackage() {
      this.v$.$validate().then(result =>{
        if (!result) return;
        this.$store.dispatch("changeLoaderValue", true)
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$http.post(`/packages/${this.product.id}/save`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("productList/UPDATE_PACKAGES", response.data.data)
          }
        })
      })
    },
    parsePackages(){
      this.defaultData = {...this.data}
      var packages = JSON.parse(JSON.stringify(this.packages))
      if(packages && packages.length){
        packages.forEach((p)=> {
          if(["basic","standard","premium"].includes(p.pack_id)){
            this.data[p.pack_id] = p;
          }
        });
      }
    },
    resetData(){
      this.data = {...this.defaultData}
      this.v$.$reset()
    },
  },
  created(){
    this.parsePackages();
  }
}
</script>
