<template>
  <div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item" v-for="(portfolio, index) in paginatedPortfolios" :key="index" >
            <div class="row">
              <div class="col-sm-4 mb-2 mb-md-0" @click="() => showImg(portfolio.id)" >
                <span class="d-inline-block position-relative">
                  <span v-if="portfolio.is_video" class="video-icon-dp">
                    <i class="ri-play-circle-line"></i>
                  </span>
                  <b-img thumbnail class="cursor-pointer" fluid :src="absoluteUrl(portfolio.thumb_path)" alt="portfolios-img"></b-img>
                </span>
              </div>
              <div class="col-12 col-md-8">
                <span class="d-flex justify-content-between">
                  <span class="text-secondary font-sm image-name me-2">{{$filters.truncate_start(portfolio.image, 24)}}</span>
                  <span>
                    <div class="dropdown no-arrow">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <button  @click.prevent="deleteItem(portfolio.id)" class="dropdown-item">
                          <i class="ri ri-delete-bin-line align-bottom me-1"></i> Delete </button>
                        </li>
                      </ul>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </li>
        </ul>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
              <div class="text-md-right mt-2 pagination-rounded">
                  <b-pagination v-model="pagination.currentPage"
                  :total-rows="portfolios.length" :limit="pagination.limit"
                  :per-page="pagination.perPage"></b-pagination>
              </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <b-button variant="info" class="mt-2 me-3" @click.prevent="uploadVideoModalShow = true">Add Video</b-button>
          <b-button variant="primary" class="mt-2" @click.prevent="uploadModalShow = true">Add Portfolios</b-button>
        </div>
      </div>
    </div>

    <b-modal v-model="uploadVideoModalShow" hide-footer centered
       title="Upload Video" title-class="font-18">
        <product-video-uploader v-if="uploadVideoModalShow"
         :productId="product.id" @closeMe="initializeAll()" />
    </b-modal>

    <b-modal v-model="uploadModalShow" hide-footer centered
      title="Add Product Portfolios" title-class="font-18">
      <product-file-uploader v-if="uploadModalShow" 
        :productId="product.id" @closeMe="initializeAll()" />
    </b-modal>

    <CoolLightBox
      :items="lightboxFiles"
      :index="lightboxIndex"
      :useZoomBar="true"
      :fullScreen="false"
      @close="lightboxIndex = null">
    </CoolLightBox>
  </div>
</template>

<script>
import ProductFileUploader from '@/components/forms/ProductFileUploader.vue';
import ProductVideoUploader from '@/components/forms/ProductVideoUploader.vue';
import CoolLightBox from '@/components/CoolLightBox.vue'

export default {
  components: {
    ProductFileUploader,
    ProductVideoUploader,
    CoolLightBox
  },
  data() {
    return {
      uploadModalShow: false,
      uploadVideoModalShow:false,
      pagination:{
        currentPage: 1,
        perPage: 10,
        limit:4
      },
      lightboxIndex: null
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product;
    },
    portfolios(){
      return this.product.portfolios
    },
    paginatedPortfolios(){
      return this.portfolios.slice(
        ((this.pagination.currentPage - 1) * this.pagination.perPage),
        ((this.pagination.currentPage - 1) * this.pagination.perPage + this.pagination.perPage)
      );
    },
    lightboxFiles(){
      return this.portfolios.map(file => {
          if(file.is_video){
            return {
                title: file.image,
                autoplay: true,
                // sizes: '50vw',
                thumb: this.absoluteUrl(file.path),
                mediaType: 'video',
                src: this.absoluteUrl(file.video_path)
              }
          }else{
            return {
                title: file.image,
                mediaType: 'image',
                src: this.absoluteUrl(file.path)
              }
          }
      })
  },
  },
  methods: {
    showImg(itemId) {
      this.lightboxIndex = this.portfolios.findIndex((item) => item.id == itemId);
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this image!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/portfolios/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("productList/DELETE_PORTFOLIO", itemId)
            }
          })
        }
      });
    },
    initializeAll(){
      this.uploadModalShow = false;
      this.uploadVideoModalShow = false;
      this.lightboxIndex = null;
    }
  },
}
</script>

<style scoped>

@media(max-width: 500px){
  .font-sm.image-name{
    font-size: 10px !important;
  }
}

.video-icon-dp{
  height: 25px;
  width: 25px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  line-height: 1.6;
  color: #fff;
  position: absolute;
  background: var(--bs-primary);
  cursor: pointer;
  text-align: center;
  transition: all 0.24s ease-in;
}

.video-icon-dp:hover{
  background: #000;
  color: #fff;
}
</style>
