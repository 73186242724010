
<template>
  <div class="product-attributes">

    <div class="row">
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item d-flex align-items-center justify-content-between" v-for="(attribute, index) in attributes" :key="index" >
            <span class="font-weight-bolder"><i class="mdi mdi-bookmark-check-outline mr-1"></i>{{attribute.name}} -  {{attribute.type}}</span>
            <span>
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <button @click.prevent="initEdit(attribute)" class="dropdown-item">
                   <i class="ri ri-edit-2-line align-bottom me-1"></i> Edit </button>
                  </li>
                   <li>
                    <button  @click.prevent="deleteItem(attribute.id)" class="dropdown-item">
                   <i class="ri ri-delete-bin-line align-bottom me-1"></i> Delete </button>
                  </li>
                </ul>
              </div>
            </span>
          </li>
        </ul>
      </div>
      <div class="col-12 mt-4 text-end">
        <b-button variant="warning" size="sm" class="me-3" @click.prevent="popupClone = true" type="button">
            Copy from </b-button>
        <b-button variant="primary" size="sm" @click.prevent="popupModalShow = true" type="button">
            Add Attributes</b-button>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update attribute':'Add attribute'"
      title-class="fs-6" @hide="initializeAll()">
      <product-attribute-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editMode="editMode" :productId="product.id" :editItem="editItem" />
    </b-modal>

    <b-modal v-model="popupClone" hide-footer centered
      title="Select product to copy from"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <select v-model="cloneFrom"
          class="form-control mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, iIndex) in products" :key="iIndex"
          :value="dPro.id" >{{dPro.name}}</option>
        </select>
        <b-button variant="primary" @click.prevent="cloneNow()" type="button">Clone</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ProductAttributeForm from '@/components/forms/ProductAttributeForm.vue'

export default {
  components: { ProductAttributeForm },
  data() {
    return {
      popupModalShow: false,
      editMode: false,
      editItem: null,
      popupClone: false,
      cloneFrom: null
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    },
    attributes(){
      return this.product.attributes
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
  },
  methods: {
    cloneNow(){
      if(!this.cloneFrom){ this.alertError("Select product"); return;}
      let data = {from: this.cloneFrom, to: this.product.id}
      this.$store.dispatch("changeLoaderValue",true)
      this.$http.post('/attributes/clone', data)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct", this.product.id)
          this.initializeAll()
        }
      })
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this attribute!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/attributes/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.$store.commit("productList/DELETE_ATTRIBUTE",itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.editItem = {...item}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
      this.popupClone = false;
      this.cloneFrom = null
    },
  },
}
</script>
