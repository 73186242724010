
<template>
  <div class="product-addons">

    <div class="row">
      <div class="col-12">
        <div class="mb-3 d-flex align-items-center justify-content-between">
            <h5 class="my-0">  Product Addons </h5>
        </div>
        <b-accordion class="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary"
           id="addonAccordionBordered">
          <b-accordion-item :title="`${addon.name} - $${addon.charge}`"
              v-for="(addon, index) in addons" :key="index"
              :visible="index == 0">
              <div>
                <h6>Charge: ${{addon.charge}}</h6>
                <p class="mb-1">Duration: {{calculateDeliveryTime(addon.duration)}}</p>
                <p class="mb-1"> {{addon.description}}</p>
                <div class="mt-3">
                  <button @click.prevent="initEdit(addon)" class="btn me-2 btn-sm btn-primary">
                  <i class="ri ri-edit-2-line align-bottom me-1"></i> Edit </button>
                  <button  @click.prevent="deleteItem(addon.id)" class="btn btn-sm btn-danger">
                  <i class="ri ri-delete-bin-line align-bottom me-1"></i> Delete </button>
                </div>
              </div>
          </b-accordion-item>
        </b-accordion>
      </div>
      <div class="col-12 mt-4 text-end">
        <b-button variant="warning" size="sm" class="me-3" @click.prevent="popupClone = true" type="button">
            Copy from </b-button>
        <b-button variant="primary" size="sm" @click.prevent="popupModalShow = true" type="button">
            Add Addon</b-button>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update addon':'Add addon'"
      title-class="fs-6" @hide="initializeAll()">
      <product-addon-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editMode="editMode" :productId="product.id" :editItem="editItem" />
    </b-modal>

    <b-modal v-model="popupClone" hide-footer centered
      title="Select product to copy from"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <select v-model="cloneFrom"
          class="form-control mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, iIndex) in products" :key="iIndex"
          :value="dPro.id" >{{dPro.name}}</option>
        </select>
        <b-button variant="primary" @click.prevent="cloneNow()" type="button">Clone</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ProductAddonForm from '@/components/forms/ProductAddonForm.vue'

export default {
  components: { ProductAddonForm },
  data() {
    return {
      popupModalShow: false,
      editMode: false,
      editItem: null,
      popupClone: false,
      cloneFrom: null
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    },
    addons(){
      return this.product.addons
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
  },
  methods: {
    cloneNow(){
      if(!this.cloneFrom){ this.alertError("Select product"); return;}
      let data = {from: this.cloneFrom, to: this.product.id}
      this.$store.dispatch("changeLoaderValue",true)
      this.$http.post('/addons/clone', data)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct", this.product.id)
          this.initializeAll()
        }
      })
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this addon!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/addons/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.$store.commit("productList/DELETE_ADDON",itemId)
            }
          })
        }
      });
    },
    initEdit(items){
      this.editMode = true;
      this.editItem = {...items}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
      this.popupClone = false;
      this.cloneFrom = null
    },
  },
}
</script>
