<template>
    <form autocomplete="off" class="">
        <div class="row g-3">
            <div class="col-md-6">
                <label for="inputName">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Enter name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">Type</label>
                <select class="form-select" v-model="data.type"
                :class="{ 'is-invalid': v$.data.type.$error }"
                    name="type" placeholder="--select make--">
                    <option disabled value="">--select type--</option>
                    <option value="check">check</option>
                    <option value="input">input</option>
                </select>
                    <div v-for="(item, index) in v$.data.type.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <b-button variant="dark" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        productId:{
            type: Number,
            default: null
        },
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name: "",
                type: "",
                product_id: this.productId
            },
        }
    },
    validations: {
        data:{
            name: { required: required},
            type: { required:  required}
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) return;
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post("/attributes/create", formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/ADD_ATTRIBUTE", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) return;
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/attributes/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/UPDATE_ATTRIBUTE", response.data.data)
                        this.resetForm()
                    }
                })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>